import { Pipe, PipeTransform } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { Prescription } from "../prescription.model";

@Pipe({
  name: "unique",
  standalone: true,
})
export class UniquePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(prescriptions: Prescription[]): Prescription[] {
    const uniquePrescriptions: Prescription[] = [];

    prescriptions.filter((prescription, index, array) => {
      const prescrName = prescription.name ? prescription.name.trim() : '';
      const prescrType = prescription.type ? this.translate.instant(prescription.type.trim()) : '';

      if (
        array
          .map((prescr) => prescr.name ? prescr.name.trim() : '')
          .indexOf(prescrName) === index ||
        array
          .map((prescr) => prescr.type ? this.translate.instant(prescr.type.trim()) : '')
          .indexOf(prescrType) === index
      ) {
        return uniquePrescriptions.push(prescription);
      }
    });
    return uniquePrescriptions;
  }
}
